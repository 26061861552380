import DashboardLayout from "src/layouts/Dashboard";

import Home from "src/views/common/home";
import Reports from "src/views/staff/reports";
import { ReportsParams } from "src/views/staff/reports/params";
import { ReportsDetails } from "src/views/staff/reports/details";
import Questions from "src/views/staff/questions";
import Forms from "src/views/staff/forms";
import CreateForm from "src/views/staff/forms/create"
import FormHistory from "src/views/staff/forms/components/data-table"
import Modules from "src/views/staff/modules";
import Staffs from "src/views/staff/staffs";
import Users from "src/views/staff/users";
import UsersParams from "src/views/staff/users/params";
import NotificationSettings from "src/views/staff/notification-settings"
import MeasurementValue from "src/views/staff/notification-settings/measurement-value"
import Synchronization from "src/views/staff/notification-settings/synchronization"
import Notifications from "src/views/staff/notifications"

import Profile from "src/views/common/profile";

const staffRoutes = {
	path: 'staff',
	element: <DashboardLayout />,
	children: [
		{ path: '', element: <Home /> },
		{ path: 'reports', element: <Reports /> },
		{ path: 'reports/:id', element: <ReportsParams /> },
		{ path: 'reports/:id/:uid', element: <ReportsDetails /> },
		{ path: 'form', element: <Forms />},
		{ path: 'form/create', element: <CreateForm />},
		{ path: 'form/history', element: <FormHistory />},
		{ path: 'questions', element: <Questions /> },
		{ path: 'devices', element: <Home /> },
		{ path: 'modules', element: <Modules /> },
		{ path: 'staffs', element: <Staffs /> },
		{ path: 'users', element: <Users /> },
		{ path: 'users/:id', element: <UsersParams /> },
		{ path: 'profile', element: <Profile /> },
		{ path: 'notification-settings', element: <NotificationSettings /> },
		{ path: 'notification-settings/measurement-value', element: <MeasurementValue /> },
		{ path: 'notification-settings/synchronization', element: <Synchronization /> },
		{ path: 'notifications', element: <Notifications /> },
	]
}

export default staffRoutes;
