import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Stats from './components/stats';

export default function Reports() {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.reports')}
			</Typography>

			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/staff'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.reports')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			<Grid container spacing={3}>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005091' name='Heart rate' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005092' name='Skin temperature' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005093' name='Blood Oxygen' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005094' name='Respiratory rate' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005095' name='BP' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005097' name='Steps' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00005098' name='Resq Score' />
				</Grid>
			</Grid>
		</React.Fragment>
	)
}