import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import UniversalCookie from "universal-cookie";

import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Loader from 'src/components/loader';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CloseIcon from '@material-ui/icons/Close';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import AddFormDialog from '../../components/add-form-dialog';

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";


import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const COOKIE = new UniversalCookie();

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  price: number,
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];


function FormHistory(props: any) {
	const { t } = useTranslation();
	const { types, editDialog, deleteDialog } = props;

  const location = useLocation();
  const id = location.state.id;
  const ariaLabel = { 'aria-label': 'description' };

	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('name');
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [all, setAll] = React.useState<any>({});
  const [questions, setQuestions] = React.useState<any>([]);
	const [value, setValue] = React.useState('1');
  const [loading, setLoading] = React.useState<boolean>(false);


	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
	
	const headCells: any = [
		{ id: 'text', align: 'left', disablePadding: false, label: 'Text' },
		{ id: 'id', align: 'left', disablePadding: false, label: 'Question Type' },
		{ id: 'email', align: 'right', disablePadding: false, label: t('table.actions') },
	];

	function handleRequestSort(event: any, property: string): void {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleEditRequest(data: any): void {
		editDialog.setState(true);
		editDialog.setData(data);
	}

	function handleDeleteRequest(data: any): void {
		deleteDialog.setState(true);
		deleteDialog.setData(data);
	}

  function fetchData(): void {
		// setLoading(true);

		api({
			pathname: '/staff/'+ COOKIE.get("workspace") + '/forms/' + id,
			method: 'GET',
			handleResponse: (response: any): void => {
				setLoading(true);

				setAll(response.data);
				setQuestions(response.data.questions);

        setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})

		// setLoading(true);
	}

	React.useEffect(fetchData, []);

  

	return (
		<Loader state={loading}>
      <Grid container justifyContent='space-between' spacing={3}>
                  <Grid item>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                          {'Create New Form'}
                      </Typography>
                      <Breadcrumbs aria-label='breadcrumb'>
                          <Link component={RouterLink} color='inherit' to='/staff'>
                              {t('navigation.dashboard')}
                          </Link>
                          <Link component={RouterLink} color='inherit' to='/staff/form'>
                              {t('navigation.forms')}
                          </Link>
                          <Typography color='text.primary'>
                              {'create'}
                          </Typography>
                      </Breadcrumbs>
                      
                  </Grid>
                  <Grid item>
                  <Stack spacing={2} direction="row">
                    <IconButton aria-label="reset">
                      <RestorePageIcon />
                    </IconButton>
                  
                    <LoadingButton variant='contained' loading={undefined} color='primary' onClick={undefined}>
                    {'Apply Edit'}
                    </LoadingButton>
                    <AddFormDialog updateRender={undefined} questions={questions} is_default={questions.is_default} name={questions.name}/>
                    </Stack>
                    </Grid>
              </Grid>
              <TabContext value={value}>
        
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                  <Tab disableRipple label="Questions" value="1" />
                  <Tab disableRipple label="Answers" value="2" />
                  <Tab disableRipple label="Settings" value="3" />
              </TabList>
              </Box>
        <TabPanel value="1">
        
            <Stack alignItems="center" justifyContent="center" spacing={3}>
                <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 5, width: '93ch' },
                    maxWidth:"100ch",
                    flexWrap: 'wrap',
                    backgroundColor: '#FAF9F6',
                    border: 1,
                    borderColor: 'grey.500', 
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '16px',
                    '&:hover': {
                    backgroundColor: '#FFFFFF',
                    opacity: [1, 1, 1],
                    },
                }}
                noValidate
                autoComplete="off"
                >
                    <Stack  spacing={3}>
                    
                        <TextField fullWidth required id="standard-basic" placeholder="Form Name" InputProps={{ style: { fontSize: 27 } }} value={all.name} variant="standard" />
                        <TextField multiline  rows={4} id="standard-basic" placeholder="Form Description" variant="standard" />
                    </Stack>
                </Box>
                
                <Stack  spacing={3}>
                {questions.map((item: any, index: number) => (
                        <Box
                        id={index.toString()}
                        key={index.toString()}
                        component="form"
                        sx={{
                            '& > :not(style)': { mt: 5, mx: 5, mb: 2, width: '93ch' },
                            maxWidth:"100ch",
                            flexWrap: 'wrap',
                            backgroundColor: '#FAF9F6',
                            border: 1,
                            borderColor: 'grey.500',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '16px',
                            '&:hover': {
                            backgroundColor: '#FFFFFF',
                            opacity: [1, 1, 1],
                            },
                        }}
                        noValidate
                        autoComplete="off"
                        >
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={8} >
                                    <Stack spacing={2}>
                                    
                                    <TextField fullWidth required id={index.toString()} placeholder={"Question Text"} defaultValue={item.text} onChange={undefined} variant="standard" />
                                    <Grid item xs={12}></Grid>
                                    {item.question_type == 1?(<Input fullWidth disabled value="Text Answer Field" inputProps={ariaLabel} />
                                    ):(item.question_type == 4?(
                                    <Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid item xs={12}><Checkbox disabled /><TextField required id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.value} /><IconButton color="primary" ><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={undefined}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                        
                                    ):(item.question_type == 3?(<Stack spacing={1}>
                                        {item.question_options.map((it: any, o_index: number) => (
                                        
                                            <Grid item xs={12}> <Radio disabled/><TextField id={o_index.toString()} placeholder={"Answer Option"} key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={it.value} /><IconButton color="primary" ><CloseIcon fontSize="small" /></IconButton></Grid>
                                        
                                        ))}
                                        <Grid item xs={12}>
                                            <IconButton color="primary" onClick={undefined}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Stack>):(item.question_type == 2?(<Input fullWidth disabled value="Number Answer Field"  />
                                    ):(item.question_type == 5?(<Input disabled value="Day/Month/Year" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(item.question_type == 6?(<Input disabled value="Time" endAdornment={
                                        <InputAdornment position="start">
                                          <AccessTimeIcon  />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />):(<Input disabled value="Day/Month/Year Hour:Minutes" endAdornment={
                                        <InputAdornment position="start">
                                          <EventIcon />
                                        </InputAdornment>
                                      } inputProps={ariaLabel} />))))))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                <FormControl fullWidth disabled>
                                <NativeSelect
                                      defaultValue={item.question_options_name}
                                      >
                                  </NativeSelect>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                </Grid>
                                <Grid item xs={3} >
                                    <IconButton aria-label="delete" onClick={undefined} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                    <FormControlLabel control={<Switch checked={item.is_required} onClick={undefined}/>} label="required" />
                                </Grid>
                            </Grid>
                        </Box>


                        
                    ))}
                    </Stack>
                
                <Button variant="outlined" color='primary' startIcon={<AddIcon />} onClick={undefined}>
                    Add Question
                </Button>                
                </Stack>
              
            
        </TabPanel>
        <TabPanel value="2">
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Dessert (100g serving)</TableCell>
          <TableCell align="right">Calories</TableCell>
          <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
          <Row key={row.name} row={row} />
        ))}
        </TableBody>
      </Table>
      </TableContainer>
      </TabPanel>
      <TabPanel value="3">
                  <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Box
                  sx={{
                      '& > :not(style)': { m: 5, width: '93ch' },
                      maxWidth:"100ch",
                      flexWrap: 'wrap',
                      backgroundColor: '#FFFFFF',
                      border: 1,
                      borderColor: 'grey.500', 
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '16px',
                  }}
                  >
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                      <Typography variant="h3"> Form Settings </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <FormControlLabel control={<Switch checked={true}/>} label="Make Default Form" />
                      <FormHelperText>Will automatically send this form to new users related to your entity</FormHelperText>
                      </Grid>
                      </Grid>
                  </Box>
                  
              
                  
                  </Stack>
                  

      </TabPanel>
      </TabContext>
    </Loader>
	);
}

export default FormHistory;
