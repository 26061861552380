import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import api from 'src/utilities/axios';
import UniversalCookie from 'universal-cookie';

interface IStatsProps {
	id: number
	name: string
	answer_progress: string
	date: string
	is_editable: boolean
	is_default: boolean
    user: object
    entity_name: string
    updateRender:any
}
  

function Stats(props: IStatsProps) {
	const { id, name, answer_progress, date, is_editable, is_default, user, entity_name,updateRender } = props;
	const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const cookie = new UniversalCookie();

    function handleDelete(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();


		api({
			pathname: '/staff/' + cookie.get('workspace') + '/forms/'+id,
			method: 'DELETE',
			
			handleResponse: (): void => {
				handleClose();
                updateRender();
			},
			handleError: (): void => {
			}
		})
	}

	return (
		
			<Card variant="outlined" sx={{ boxShadow: 6, pb: 2, maxHeight: 402, minHeight: 402}}>
            <CardHeader
                    action={
                    <IconButton aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    }
                />
                <Menu
                    id="long-menu"
                    MenuListProps={{
                    'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        <FileCopyIcon />
                        Duplicate
                    </MenuItem>
                    <MenuItem onClick={handleDelete} disableRipple>
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </Menu>
                {/* <Box component={RouterLink} to={'/staff/form/' + id + '/history'}> */}
                <Box component={RouterLink} to='/staff/form/history' state={{ id: id }}>
				<CardContent sx={{ textAlign: 'center', py: 0}}>
					<Typography variant='h1' fontWeight='fontWeightLight' sx={{ pt: 0, pb: 1 }}>
						<Box>
							{name}
						</Box>
					</Typography>
					<DescriptionSharpIcon sx={{ fontSize: 99, color:'gray' }}></DescriptionSharpIcon>
					<Typography variant='h3' sx={{ pt: 1, pb: 3 }}>
						<Box fontWeight='fontWeightLight' color={'gray'}>
							{date}
						</Box>
					</Typography>
				</CardContent>
				
				<CardContent sx={{ textAlign: 'right', pt: 2}}>
					<Typography sx={{ mb: 0 }} color="text.secondary">
						{entity_name}
					</Typography>
				</CardContent>
                </Box>
			</Card>
		// </Box>
	);
}

export default Stats;