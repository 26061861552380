import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';
import { CSVLink } from 'react-csv';

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import api from 'src/utilities/axios';
import { getTimestamp } from 'src/utilities/date';

function Csv(props: any) {
	const { query, id, uid } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();
	
	const [startDate, setStartDate] = React.useState<string>();
	const [endDate, setEndDate] = React.useState<string>();
	const [interval, setInterval] = React.useState<string>('10m');
	const [data, setData] = React.useState<any>([]);
	const [filename, setFilename] = React.useState<string>();

	const csvLink: any = React.useRef();

	function handleSubmit(event: any) {
		event.preventDefault();

		let startUnixDate = Date.parse(startDate);
		let endUnixDate = Date.parse(endDate);

		let pathname = '/staff/' + cookie.get('workspace') + '/reports/' + id + '/' + uid + '/download'
		pathname += '?start_time=' + startUnixDate / 1000;
		pathname += '&end_time=' + endUnixDate / 1000;
		pathname += '&interval=' + interval;

		api({
			pathname: pathname,
			method: 'GET',
			handleResponse: (response: any): void => {
				let csv_data = [];
				if(id === "00005095"){
					let dbpData = response.data.report.values_dbp;
					response.data.report.values.map((item: any, index:number) => {
						csv_data.push({
							date: item[0],
							sbp_max: item[1],
							sbp_min: item[2],
							sbp_mean: item[3],
							dbp_max: dbpData[index][1],
							dbp_min: dbpData[index][2],
							dbp_mean: dbpData[index][3]
						})
					});}
				else{
					response.data.report.values.map((item: any) => {
						csv_data.push({
							date: item[0],
							max: item[1],
							min: item[2],
							mean: item[3]
						})
					
				});

				}

				// setFilename(query.email + '_' + cookie.get('workspace') + '_' + getTimestamp() + '.csv')
				setFilename(t('report.'+id) + '(' +query.name + ')_' + getTimestamp() + '.csv')
				setData(csv_data);
				
				csvLink.current.link.click()
			},
			handleError: (error: any): void => {
				console.log(error);
			}
		})
	}

    return (
		<Grid container justifyContent='space-between' alignItems='center' spacing={3} sx={{ px: 2, py: 3 }}>
			<Grid item xs={12}>
				<Typography variant='h6' display='inline-block'>
					{t('report.csv_download_title')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<form onSubmit={handleSubmit}>
					<TextField
						id="datetime-local"
						label={t('report.starting_date')}
						type="datetime-local"
						onChange={(event) => setStartDate(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mr: 1 }}
						required
					/>
					<TextField
						id="datetime-local"
						label={t('report.end_date')}
						type="datetime-local"
						onChange={(event) => setEndDate(event.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ mx: 1 }}
						required
					/>
					<FormControl sx={{ textAlign: 'left', mx: 1 }} required>
						<InputLabel id='interval-label'>{t('date.interval')}</InputLabel>
						<Select
							label={t('date.interval')}
							id='interval'
							value={interval}
							labelId='interval-label'
							onChange={(event) => setInterval(event.target.value)}
						>
							<MenuItem value='10m'>{t('date.10m')}</MenuItem>
							<MenuItem value='1hour'>{t('date.1hour')}</MenuItem>
							<MenuItem value='1day'>{t('date.1day')}</MenuItem>
							<MenuItem value='1month'>{t('date.1month')}</MenuItem>
						</Select>
					</FormControl>
					<Button
						variant='contained'
						color='primary'
						type='submit'
						sx={{ mx: 1, float: 'right' }}
					>
						{t('report.csv_download_btn')}
					</Button>
					{id==="00005095"?(<CSVLink
						filename={filename}
						data={data}
						headers={[
							{ label: t('table.date'), key: 'date' },
							{ label: t('report.sbp') + t('report.score_max'), key: 'sbp_max'},
							{ label: t('report.sbp') + t('report.score_min'), key: 'sbp_min'},
							{ label: t('report.sbp') + t('report.score_mean'), key: 'sbp_mean'},
							{ label: t('report.dbp') + t('report.score_max'), key: 'dbp_max'},
							{ label: t('report.dbp') + t('report.score_min'), key: 'dbp_min'},
							{ label: t('report.dbp') + t('report.score_mean'), key: 'dbp_mean'},
						]}
						ref={csvLink}
					>
					</CSVLink>):(<CSVLink
						filename={filename}
						data={data}
						headers={[
							{ label: t('table.date'), key: 'date' },
							{ label: t('report.score_max'), key: 'max'},
							{ label: t('report.score_min'), key: 'min'},
							{ label: t('report.score_mean'), key: 'mean'},
						]}
						ref={csvLink}
					>
					</CSVLink>)}
					
				</form>
			</Grid>
        </Grid>
    )
}

export default Csv;
