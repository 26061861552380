import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import LanguageMenu from 'src/components/language-menu';
import firebase from 'src/utilities/firebase';
import api from 'src/utilities/axios';


export default function SignIn(): JSX.Element {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	function handleSignIn(event: React.SyntheticEvent) {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			email: { value: string };
			password: { value: string };
		}

		api({
			pathname: '/admin/login',
			method: 'POST',
			data: target.email.value,
			handleResponse: (response: any) => {
				if (response.data === true){ 
					cookie.set('userType', 'admin', { path: '/' });
					navigate('/admin');}
				else{
					setMessage(<Alert variant='filled' severity='error'>スーパーユーザーでサインインしてください。</Alert>)}
			},
			handleError: (error: any) => {
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>)
			}
		})

		firebase
			.auth()
			.signInWithEmailAndPassword(target.email.value, target.password.value)
			.then((response: any): void => {
				if (response.user.emailVerified) {
					cookie.set('userEmail', response.user.email, { path: '/' });
					// cookie.set('userType', 'admin', { path: '/' });

					// navigate('/admin');
				} else {
					const user = firebase.auth().currentUser;

					user
						.sendEmailVerification()
						.then(() => {
							setMessage(<Alert variant='filled' severity='error'>メールは確認されていません！ メールを確認してください。</Alert>)
						})
						.catch((err: any) => {
							setMessage(<Alert variant='filled' severity='error'>メールは確認されていません！ メールを確認してください。</Alert>)
						});

					firebase.auth().signOut()
				}

				setLoading(false);
			})
			.catch((error: any): void => {
				setLoading(false);
				setMessage(<Alert variant='filled' severity='error'>{error.message}</Alert>)
			})
	}

	return (
		<React.Fragment>
			<div style={{ textAlign: 'center'}}>
				<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
			</div>
			<br />
			{message}
			<br />
			<form onSubmit={handleSignIn}>
				<TextField
					label={t('form.email')}
					variant='outlined'
					name='email'
					type='email'
					autoComplete='email'
					autoFocus
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<TextField
					label={t('form.password')}
					variant='outlined'
					name='password'
					type='password'
					autoComplete='current-password'
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<LoadingButton
					component='button'
					type='submit'
					fullWidth
					variant='contained'
					loading={loading}
					sx={{ mb: 1 }}
				>
					{t('form.sign_in')}
				</LoadingButton>
				<Button
					component={RouterLink}
					to='/auth/sign-up'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('form.create_account')}
				</Button>
				<Button
					component={RouterLink}
					to='/auth/forgot-password'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('form.forgot_password')}
				</Button>
				<Button
					component='a'
					href='https://ad-dice.com/healthcare-ai/'
					fullWidth
					sx={{ mb: 1 }}
				>
					{t('common.about')}
				</Button>
			</form>
			<LanguageMenu />
		</React.Fragment>
	);
}