import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';
import React from 'react';
import api from 'src/utilities/axios';
import Chip from '@material-ui/core/Chip';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

import LoadingButton from '@material-ui/lab/LoadingButton';
import BorderColor from '@material-ui/icons/BorderColor';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import DeleteFormDialog from './delete-data-dialog';
import { JsxElement } from 'typescript';

// Custom Component import
import LockSwitch from './components/lock-switch';


function Manage(props: any) {
	const { data } = props;
	const { t } = useTranslation();
	const [editText, setEditText] = React.useState<boolean>(true);
	const [description, setDescription] = React.useState<string>(data.description);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>(data.name);
	const [isPublic, setIsPublic] = React.useState<boolean>(data.is_public);
	const [isAllowed, setIsAllowed] = React.useState<boolean>(data.notification_setting_status === "unlocked" ? true : false);
	const [render, setRender] = React.useState<boolean>(false);

	const updateRender = (): void => setRender(!render);

	function PublicRender(): JSX.Element {

		if (isPublic) {

			return (
				<TableCell align='left'><Chip label={t('table.public')} color="info" /></TableCell>

			)
		} else {

			return (

				<TableCell align='left'><Chip label={t('table.private')} /></TableCell>

			)
		}
	}

	const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(e.target.checked);
        setIsAllowed(e.target.checked);
    }

	const changeValue = (e: any) => {
		if (e.target.id == "name") {
			setName(e.target.value);
		} else if (e.target.id == "description") {
			setDescription(e.target.value);
		} else {
			isPublic ? setIsPublic(false) : setIsPublic(true);
		}
	}


	function handleEdit(e:any) {
		if (!loading) {
			if (editText && e) {
				setEditText(false);
			} else {
				setEditText(true);
			}
		} else return null;

	}
	const handleChange = (event: React.SyntheticEvent) => {
		setLoading(true);
		event.preventDefault();
		event.persist();


		api({
			pathname: '/entities/' + data.workspace_name,
			method: 'PUT',
			data: {
				name: name,
				description: description,
				is_public: isPublic,
				notification_setting_status: isAllowed ? "unlocked" : "locked"
			},
			handleResponse: (): void => {

				setLoading(false);
				setEditText(true);

			},
			handleError: (): void => {
				setLoading(false);
			}

		})


	};







	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item xs={12}>
					<Box sx={{ mx: 2, my: 3 }}>
						<Typography variant='h6' display='inline'>
							{"ID" + ": " + data.workspace_name}<Box sx={{ m: 1 }} />
							{editText ?
								<Tooltip title={t('data.data_modify')}>
									<LoadingButton onClick={handleEdit}>
										<BorderColor sx={{ fontSize: 35 }} />
									</LoadingButton>
								</Tooltip>
								:
								<Tooltip title={t('data.data_change')}>
									<LoadingButton
										loading={loading}
										type="submit"
										onClick={handleChange}>
										<CheckIcon sx={{ fontSize: 35 }} />
									</LoadingButton>
								</Tooltip>}


						</Typography>

					</Box>
				</Grid>

				<Grid item xs={12}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell sx={{ width: 270 }}>
									{t('table.name')}
								</TableCell>
								<TableCell align='left'>
								
										{editText ? name :
											<form >
												<TextField
													multiline
													defaultValue={name}
													sx={{ m: 1, width: '80ch' }}
													id="name"
													name="name"
													onChange={changeValue}
												/>
											</form>}
								
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>

									{t('table.description')}
									
								</TableCell>

								<TableCell>
									{editText ? description :
										
											<form >
												<TextField
													multiline
													defaultValue={description}
													sx={{ m: 1, width: '80ch' }}
													id="description"
													name="description"
													onChange={changeValue}
												/>
											</form>
										
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>
									<b>{t('table.status')}</b>
								</TableCell>
								{editText ? <PublicRender /> :
									<>
										<TableCell>
											<Tooltip title={isPublic ? t('table.is_private') : t('table.is_public')}>
												<Switch checked={isPublic} onChange={changeValue} />
											</Tooltip>

										</TableCell>
									</>
								}
							</TableRow>

							{/* NOTIFICATION SETTINGS STATUS START */}
							<TableRow>
								<TableCell align="left">
									{t("notification_settings.notification_settings_status")}
								</TableCell>
								<TableCell align="left">
									{
										editText ? 
											(isAllowed ? t("notification_settings.status.unlocked") : t("notification_settings.status.locked")) :
											(
												<LockSwitch 
													checked={isAllowed}
													onChange={handleSwitchChange}
													label={
														isAllowed
															? t("notification_settings.is_allowed")
															: t("notification_settings.not_allowed")
													}
												/>
											)
									}
								</TableCell>
							</TableRow>
							{/* NOTIFICATION SETTINGS STATUS END */}
							{editText ? null :
								
								<TableRow>

									   <Box component="td" marginLeft={50} /> 
										<TableCell  align='right'>
										
												<><DeleteFormDialog updateRender={updateRender} data={data.workspace_name} /></>
											
										</TableCell></TableRow>

							}

						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>
	)
}

export default withRouter(Manage);