import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api from 'src/utilities/axios';
import UniversalCookie from "universal-cookie";

import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Loader from 'src/components/loader';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import Divider from '@material-ui/core/Divider';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CloseIcon from '@material-ui/icons/Close';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import AddSharpIcon from '@material-ui/icons/AddSharp';

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";


import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const COOKIE = new UniversalCookie();

function FormDetail(props: any) {
	const { t } = useTranslation();
	const { types, editDialog, deleteDialog } = props;

    const location = useLocation();
    //   const id = location.state.id;
    const ariaLabel = { 'aria-label': 'description' };

	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('name');
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [all, setAll] = React.useState<any>({});
    const [questions, setQuestions] = React.useState<any>([]);
	const [value, setValue] = React.useState('1');
    const [loading, setLoading] = React.useState<boolean>(false);


	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };
	
	const data: any = [
		{ id: 'text', name: 'name', description: 'false', label: 'Text' },
		{ id: 'id', name: 'name hoer', description: 'false', label: 'Question Type' },
		{ id: 'email', name: 'namae', description: 'false', label: t('table.actions') },
	];

	function handleRequestSort(event: any, property: string): void {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleEditRequest(data: any): void {
		editDialog.setState(true);
		editDialog.setData(data);
	}

	function handleDeleteRequest(data: any): void {
		deleteDialog.setState(true);
		deleteDialog.setData(data);
	}
  

	return (
		<Loader state={loading}>
      <Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.reports')}
			</Typography>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/client'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.reports')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{'Not Answered'}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.description')}</TableCell>
								<TableCell>{t('table.last_modified')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>{t('data.'+item.id+'_title', item.name)}</TableCell>
										<TableCell>{t('data.'+item.id+'_description', item.description)}</TableCell>
										<TableCell>{'lastupdated'}</TableCell>
										<TableCell align='right'>
											<Tooltip title={t('table.detailed')}>
												<IconButton component={RouterLink} to={'/client/forms/answer/'}  state={{ id: 12, sent_order: 1 }} >
                        <Button variant="contained" color="success">
                          Answer
                        </Button>
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>

    <Divider sx={{ my: 3 }} />
         
    <Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{'Answer History'}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('table.name')}</TableCell>
								<TableCell>{t('table.description')}</TableCell>
								<TableCell>{t('table.last_modified')}</TableCell>
								<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((item: any, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>{t('data.'+item.id+'_title', item.name)}</TableCell>
										<TableCell>{t('data.'+item.id+'_description', item.description)}</TableCell>
										<TableCell>{'lastupdated'}</TableCell>
										<TableCell align='right'>
											<Tooltip title={t('table.detailed')}>
												<IconButton component={RouterLink} to={'/client/data/' + item.id}>
													<VisibilitySharpIcon />
												</IconButton>
											</Tooltip>
                      <Tooltip title={t('table.detailed')}>
												<IconButton component={RouterLink} to={'/client/data/' + item.id}>
													<DeleteSharpIcon />
												</IconButton>
											</Tooltip>
                      <Tooltip title={t('table.detailed')}>
												<IconButton component={RouterLink} to={'/client/data/' + item.id}>
													<EditSharpIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Card>

    </Loader>
	);
}

export default FormDetail;
