import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import UniversalCookie from "universal-cookie";
import moment from "moment";
import {
  Breadcrumbs,
  Card,
  Chip,
  Divider,
  Link,
  Grid,
  Typography,
  Tooltip, 
  Button
} from "@material-ui/core";
import Loader from "src/components/loader";
import { BarChart } from "./components/bar-chart";
import { BpChart } from "./components/bp-chart";

import { GraphPagination } from "./components/graph-pagination";
import Csv from "./components/csv";
import { FilterDialog } from "./components/filter-dialog";
import { StepGraph } from "./components/step-graph/container";
import { StatsCard } from "./components/stats-card";
import { calculateEndTimeUnix } from "src/utilities/date/index.new";
import api from "src/utilities/axios";
import { StaffReportStateType } from "src/redux/staff-report/reducers";
import { removeMilliseconds } from "src/utilities/date";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export interface IPublicProps {
  params: any;
}

export interface IReduxStateProps {
  reduxState: {
    staffReport: StaffReportStateType;
  };
}

export interface IReduxDispatchProps {
  setStartDate: (_: any) => void;
  setComparingDataSet: (_: any) => void;
}

export type Props = IPublicProps & IReduxDispatchProps & IReduxStateProps;

const COOKIE = new UniversalCookie();

export function ReportsDetailsComponent(props: Props): JSX.Element {
  const { params } = props;
  const { startDate, interval, comparingDataSet } = props.reduxState.staffReport;
  const location = useLocation();
  const { t } = useTranslation();
  const query = queryString.parse(location.search);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [dbpLoading, setDbpLoading] = React.useState<boolean>(true);
  const [filterLoading, setFilterLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [dbp_data, setDbpData] = React.useState<any>({});

  function fetchData(): void {
    setLoading(true);

    let pathname =
      "/staff/" +
      COOKIE.get("workspace") +
      "/reports/" +
      params.id +
      "/" +
      params.uid;
    pathname += "?start_time=" + removeMilliseconds(startDate);
    pathname += "&end_time=" + calculateEndTimeUnix(interval, startDate);
    pathname += "&interval=" + interval;

    api({
      pathname: pathname,
      method: "GET",
      handleResponse: (response: any): void => {
        setData(response.data.report);
        if (params.id !== "00005095"){
          setDbpLoading(false)
        }
        setLoading(false);
      },
      handleError: (): void => {
        setLoading(false);
      },
    });
    if (params.id === "00005095"){
      fetchDbpData();
    }
  }

  function fetchDbpData(): void {
    setDbpLoading(true);

    let pathname =
      "/staff/" +
      COOKIE.get("workspace") +
      "/reports/00005096/" +
      params.uid;
    pathname += "?start_time=" + removeMilliseconds(startDate);
    pathname += "&end_time=" + calculateEndTimeUnix(interval, startDate);
    pathname += "&interval=" + interval;

    api({
      pathname: pathname,
      method: "GET",
      handleResponse: (response: any): void => {
        setDbpData(response.data.report);
        setDbpLoading(false);
      },
      handleError: (): void => {
        setDbpLoading(false);
      },
    });
  }

  function renderCompareGraph() {
    return comparingDataSet.map((item: any) => {
      if (!item) return;
      return (
        <Card key={item.id} sx={{ mb: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            sx={{ px: 2, py: 3 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" display="inline-block">
                {t("report." + item.id)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <StatsCard values={item.values} values_dbp={dbp_data.values} id={item.id} additional={true} />
            </Grid>
            <Grid item xs={9}>
              {item.id === "00005097" ? (
                <StepGraph
                  values={item.values && item.values}
                  interval={interval}
                  min={item.y_min}
                  max={item.y_max}
                  reportId={item.id}
                />
              ) : (item.id === "00005095" ?(<BpChart
                values={item.values && item.values}
                values_dbp={dbp_data.values && dbp_data.values}
                name={item.name}
                min={dbp_data.y_min}
                max={item.y_max}
                reportId={item.id}
              />):(
                <BarChart
                  values={item.values && item.values}
                  name={item.name}
                  min={item.y_min}
                  max={item.y_max}
                  reportId={item.id}
                />
              ))}
            </Grid>
          </Grid>
        </Card>
      );
    });
  }

  React.useEffect(fetchData, [startDate, interval]);

  return (
    <div>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t("report." + params.id, data.name)}
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} color="inherit" to="/staff">
              {t("navigation.dashboard")}
            </Link>
            <Link component={RouterLink} color="inherit" to="/staff/reports">
              {t("navigation.reports")}
            </Link>
            <Link
              component={RouterLink}
              color="inherit"
              to={"/staff/reports/" + params.id}
            >
              {t("report." + params.id, data.name)}
            </Link>
            <Typography color="text.primary">
              {query.name} ( {query.email} )
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Card sx={{ mb: 3 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={12}>
            <Csv query={query} id={params.id} uid={params.uid} />
          </Grid>
        </Grid>
      </Card>

      <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Chip
            label={
              t("report.starting_date") + " : " + moment(startDate).format("L")
            }
          />
        </Grid>
        <Grid item>
          <Chip label={t("date.interval") + " : " + t("date." + interval)} />
        </Grid>
        <Grid item>
          <GraphPagination />
        </Grid>
        <Grid item>
          <FilterDialog params={params} setGraphLoading={setFilterLoading} setDbpData={setDbpData} />
          <Tooltip title={t('tooltip.staff.report.filter')} >
						<Button sx={{color: 'text.secondary'}}>
							<HelpOutlineIcon></HelpOutlineIcon>
						</Button>
					</Tooltip>
        </Grid>
      </Grid>

      <Loader state={loading || dbpLoading}>
        <Card sx={{ mb: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            sx={{ px: 2, py: 3 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" display="inline-block">
                {t("report.graph")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StatsCard values={data.values} values_dbp={dbp_data.values} id={data.id} additional={true} />
            </Grid>
            <Grid item xs={12} sm={9}>
              {params.id === "00005097" ? (
                <StepGraph
                  values={data.values && data.values}
                  interval={interval}
                  min={data.y_min}
                  max={data.y_max}
                  reportId={params.id}
                />
              ) : (params.id === "00005095"? (<BpChart
                values={data.values && data.values}
                name={data.name}
                values_dbp = {dbp_data.values && dbp_data.values}
                min={dbp_data.y_min}
                max={data.y_max}
                reportId={params.id}
                />):(<BarChart
                values={data.values && data.values}
                name={data.name}
                min={data.y_min}
                max={data.y_max}
                reportId={params.id}
              />))
              }
            </Grid>
          </Grid>
        </Card>
        {renderCompareGraph()}
      </Loader>
    </div>
  );
}
