import { Fragment } from 'react';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import StorageSharpIcon from '@material-ui/icons/StorageSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import DownloadIcon from '@material-ui/icons/Download';

const admin = [
	{
		href: '/admin/entities',
		icon: BusinessSharpIcon,
		title: 'navigation.entities',
		badge: Fragment,
	},
	{
		href: '/admin/CSVdownload',
		icon: DownloadIcon,
		title: 'CSV',
		badge: Fragment,
	}
]

export default admin;