import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Stack from '@material-ui/core/Stack';
import Alert from '@material-ui/core/Alert';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

import LoadingButton from '@material-ui/lab/LoadingButton';

import AddSharpIcon from '@material-ui/icons/AddSharp';

import api from 'src/utilities/axios';
import { JsxChild } from 'typescript';

interface IAddFormDialogProps {
	updateRender: any
}

function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie;

	const [open, setOpen] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [isPublic, setIsPublic] = React.useState<boolean>(false);
	const [message, setmessage] = React.useState<JSX.Element>(null);
	const [ID,setID] = React.useState<string>(null);


	function checkID():any{
		const checkLength = (ID.length < 3 || ID.length > 20);
		if (checkLength == true) {
			setmessage(<Alert severity='error'>{t("tooltip.entityID_wordcount")}</Alert>);
			return false;
		}else if(cantJapanLang(ID)==false){
			setmessage(<Alert severity='error'>{t("tooltip.entityID_ableChar")}</Alert>);
			return false;
		}else return true;
	}

	function handleAdd(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		const target = event.target as typeof event.target & {
			entityId: { value: string },
			name: { value: string },
			description: { value: string },
			is_public: { value: boolean }

		}

		if(checkID()==false){
			return null
		}else{
		
			setLoading(true);
			api({
				pathname: '/entities',
				method: 'POST',
				data: {
					workspace_name: target.entityId.value,
					name: target.name.value,
					description: target.description.value,
					is_public: isPublic

				},

				handleResponse: (): void => {
					setLoading(true);
					updateRender();
					handleClose();
				},
				handleError: (): void => {
					setLoading(false);
				}

			})

		}
		
	}

	function cantJapanLang(str): boolean {
		str = (str == null) ? "" : str;
		if (str.match(/^[A-Za-z0-9]*$/)) {
			return true;
		} else {
			return false;
		}
	}

	function changePublic(e: any): void {
		isPublic ? setIsPublic(false) : setIsPublic(true);
	};


	function handleClickOpen(): void {
		setOpen(true);
	};

	function handleClose(): void {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button variant='contained' color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {t('form.add_entity')}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
				{message}
				<form onSubmit={handleAdd} id="submitform">
					<DialogTitle>{t('form.add_entity')}</DialogTitle>
					<DialogContent>
						<Box
							sx={{ height: 10 }} />
						<TextField
							label={t('form.entity_id')}
							id='entityId'
							name='entityId'
							variant='outlined'
							fullWidth
							required
							onChange={(e)=>setID(e.target.value)}
						/>
						<Box
							sx={{ height: 10 }} />
						<TextField
							label={t('form.entity_name')}
							required
							id='name'
							name='name'
							fullWidth

						/>
						<Box
							sx={{ height: 10 }} />
						<TextField
							label={t('table.description')}
							required
							id='description'
							name='description'

							fullWidth
						/><Box
							sx={{ height: 10 }} />

						<Stack direction="row" spacing={2}>


							<Tooltip title={isPublic ? t("table.is_private") : t("table.is_public")}>
								<Switch onChange={changePublic} />
							</Tooltip>
							<DialogContentText sx={{ p: 1 }}>
								{t("table.public")+" or "+t("table.private")}
							</DialogContentText>
						</Stack>

					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color='primary'
						>
							{t('form.cancel')}
						</Button>
						<LoadingButton
							component='button'
							type='submit'
							variant='contained'
							color='primary'
							loading={loading}

						>
							{t('form.add')}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	)
}

export default AddFormDialog