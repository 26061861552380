import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingButton from '@material-ui/lab/LoadingButton';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import UniversalCookie from 'universal-cookie';

import api from 'src/utilities/axios';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';

interface IAddFormDialogProps {
	updateRender: any,
	questions: any,
	is_default: boolean,
	name: string
}

export default function AddFormDialog(props: IAddFormDialogProps): JSX.Element {
	const { updateRender, questions, is_default, name } = props
	const cookie = new UniversalCookie();
	const [open, setOpen] = React.useState<boolean>(false);
	const [arbitraryKey, setArbitraryKey] = React.useState<number>(1);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [addLoading, setAddLoading] = React.useState<boolean>(false);
	const [usersList, setUsersList] = React.useState<any[]>([]);
	const [sendList, setSendList] = React.useState<any[]>([]);

	const { t } = useTranslation();

	// useEffect(() => {
	// 	console.log('HERE: ', sendList);
	// 	console.log(questions);
	// 	console.log(is_default);
	// 	console.log(name);
	// 	let user_ids = sendList.map(a => a.uid);
	// 	console.log(user_ids);

	//   }, [sendList])

	function fetchUsersList(): void {
        api({
            pathname: '/staff/' + cookie.get('workspace') + '/users',
            method: 'GET',
            handleResponse: (response: any): void => {
				
                setUsersList(response.data);
				setSendList(response.data);
                console.log(response.data);
				setLoading(false);
				setOpen(true);
            },
            handleError: (): void => {
            }
        })
    }

	
	function handleSubmit(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setAddLoading(true);
		let user_ids = sendList.map(a => a.uid);

		api({
			pathname: '/staff/' + cookie.get('workspace') + '/forms',
			method: 'POST',
			
			data: {
				questions: questions,
				is_editable: true,
				is_default:is_default,
				name:name,
				users:user_ids

			},
			handleResponse: (): void => {
				setLoading(false);
				updateRender();
				handleClose();
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	
	function handleClickOpen(): void {
		setLoading(true);
		fetchUsersList();
		
	};

	function handleSelectAll(): void {
		setSendList(usersList);
		setArbitraryKey(arbitraryKey+1);

	};

	const handleSendList = (value:any) => {
		setSendList(value);

	};

	function handleClose(): void {
		setOpen(false);
	};


	return (
		<React.Fragment>
			<LoadingButton variant='contained' loading={loading} color='primary' onClick={handleClickOpen}>
				<AddSharpIcon /> {'Add Form'}
			</LoadingButton>
			<Dialog open={open} maxWidth='sm'  sx={{
					justifyContent: 'center'
				}} fullWidth>
			<DialogTitle>Send List</DialogTitle>
			<DialogContent >
			
			
			<Autocomplete
				multiple
				id="checkboxes-tags-demo"
				options={usersList}
				defaultValue={usersList}
				disableCloseOnSelect
				limitTags={20}
				key={arbitraryKey}
				forcePopupIcon={false}
				onChange={(event, value) => handleSendList(value)} 
				getOptionLabel={(option) => option.name}
				renderInput={(params) => (
					// <Paper style={{height: 200,overflow: "hidden",display: "flex",
					// flexDirection: "column", overflowY: 'auto'}}>
					<TextField {...params} hiddenLabel variant="standard" placeholder=" Edit List" />
					// </Paper>
				)}
				/>
					
				</DialogContent>
				
				<DialogActions>					
					<Button
						onClick={handleClose}
						color='primary'
					>
						{t('form.cancel')}
					</Button>
					<Button
						onClick={handleSelectAll}
						color='primary'
					>
						Select All
					</Button>
					<LoadingButton
							component='button'
							type='submit'
							
							variant='contained'
							color='primary'
							loading={addLoading}
							onClick={handleSubmit}
						>
							{t('form.register')}
					</LoadingButton>

				</DialogActions>

			</Dialog>
		</React.Fragment>
	)
}