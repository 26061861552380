import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import api from 'src/utilities/axios';
import TableCell from '@material-ui/core/TableCell';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import VisibilitySharp from '@material-ui/icons/VisibilitySharp';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { AlignRight } from 'react-feather';



interface IDeleteFormDialogProps {
  updateRender: any,
  data: string
}

export default function DelatedataDialog(props: IDeleteFormDialogProps,): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const [data, setData] = React.useState<string>(props.data);
  const { updateRender } = props.updateRender;
  const [deleted, setDeleted] = React.useState<boolean>(false);
  const { t } = useTranslation();

  function deleteRequest(): void {

    api({
      pathname: '/entities/' + data,
      method: 'DELETE',
      handleResponse: (): void => {
        setData(null);
        setDeleted(true);
        updateRender();
      },
      handleError: (): void => {
      }
    })

  }

  const RenderDeleted = (): JSX.Element => {
    if (deleted) {
      return (
        <>{t('form.deleted')}</>
      )
    }
    else
      return (
        <>
          <>{t('form.confirm_delete_entity')}</><br /><br />
          {"　" + t('form.entity_id') + "：" + data}
        </>
      )
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      <>
      
      <Tooltip title={t('form.delete_entity')}>
        <Button size="small" variant='contained' color='primary' onClick={handleClickOpen}>
          <CloseIcon /> {t('form.delete_entity')}
        </Button>
      </Tooltip>
      </>
      <Dialog open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle><RenderDeleted /></DialogTitle>
        <DialogContent>
          {deleted ?
            <IconButton component={RouterLink}
              to={'/admin/entities'}><CloseIcon /></IconButton> :
            <>
            
              <Box component="span" marginLeft={50} >
                <Button size="small" onClick={() => setOpen(false)}>
                  {"　" + t('form.cancel')}
                </Button>
                <Button size="small" variant='contained' color='primary' onClick={deleteRequest}>
                  <CloseIcon /> {t('form.delete')}
                </Button>
              </Box>
            </>

          }
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}