import React from 'react';
import { useTranslation } from 'react-i18next';
import withRouter from 'src/hoc/withRouter';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField } from '@material-ui/core';

import AddSharpIcon from '@material-ui/icons/AddSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function ModuleSelect(props: any): any {
	const { data = {}, onChange } = props;
	const [value, setValue] = React.useState<any>('');

	function handleValue(event: any): void {
		onChange(data.id, event.target.value)
	}

	React.useEffect(() => {
		setValue(props.data.subscription_mode ? props.data.subscription_mode : '')
	}, [props])

	if (data.module_subscription_mode && data.status === 'subscribed')
		return (
			<Box sx={{ width: '115px', display: 'inline-block', mr: 2 }}>
				<FormControl fullWidth size='small' margin='dense'>
				
					<Select
						id='demo-simple-select'
						value={value}
						onChange={handleValue}
					>
						{data.module_subscription_mode.options && data.module_subscription_mode.options.map((item: any, index: number) => {
							return (
								<MenuItem key={index} value={item.id}>{item.text}</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</Box>
		)
	else
		return null;
}

function DataTable(props: any): JSX.Element{
	const { data = [], updateRender, params } = props;
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState<boolean>(false);

	
	
	function applyRequest(index: number, id: number): void {
		setLoading(true);

		api({
			pathname: 'entities/' + params.workspace_name + '/modules/' + id,
			method: 'POST',
			handleResponse: (): void => {
				props.data[index].status = 'subscribed';
				updateRender();
				setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function deleteRequest(index: number, id: number): void {
		setLoading(true);

		api({
			pathname: 'entities/' + params.workspace_name + '/modules/' + id,
			method: 'DELETE',
			handleResponse: (): void => {
				props.data[index].status = null;
				updateRender();
				setLoading(false);
			},
			handleError: (error: any): void => {
				console.log(error)
				setLoading(false);
			}
		})
	}

	function selectRequest(id: number, value: any): void {
		setLoading(true);

		api({
			pathname: '/entities/' + params.workspace_name + '/modules/' + id + '/mode',
			method: 'POST',
			data: {
				mode_option: value
			},
			handleResponse: (): void => {
				updateRender();
				setLoading(false);
			},
			handleError: (): void => {
				setLoading(false);
			}
		})
	}

	function renderArrayData(): JSX.Element[] {
		const dataObj = [
			{id : 1,name :t('report.equipping_ratio'),description:t('report.equipping_ratio_desc')},
			{id : 2,name :t('report.red_zone_rate'),description:t('report.red_zone_rate_desc')},
			{id : 3,name :t('report.yellow_zone_rate'),description:t('report.yellow_zone_rate_desc')},
			{id : 4,name :"kpi4",description:"説明"},
			{id : 5,name :"kpi5",description:"説明"}
		];
		
		return dataObj.map((item: any, index: number): JSX.Element => {
			return (
				<TableRow key={index}>
					<TableCell component='th' scope='row'>{item.name}</TableCell>
					<TableCell align='left'>{item.description}</TableCell>
					
					<TableCell align='right'>
						<ModuleSelect data={item} onChange={selectRequest} />
					</TableCell> 
					<TableCell align='right'>
						<Button 
							sx={{ width: '100px'}}
							variant='contained'
							onClick={item.status === 'subscribed' ? () => deleteRequest(index, item.id) : () => applyRequest(index, item.id)}
						>
							{item.status === 'subscribed'
								? (<><CloseSharpIcon /> {t('table.module_unsubscribe')}</>)
								: (<><AddSharpIcon /> {t('table.module_subscribe')}</>)
							}
						</Button>
					</TableCell>
				</TableRow>
			)
		}
	)}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item xs={12}>
					<Box sx={{ mx: 2, my: 3 }}>
						<Typography variant='h6' display='inline'>
							{t('report.various_kpi')}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Loader state={loading}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t('table.name')}</TableCell>
									<TableCell align='left'>{t('table.description')}</TableCell>
									<TableCell align='right'></TableCell>
									<TableCell align='right'><Box pr={1.5}>{t('table.actions')}</Box></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{renderArrayData()}
							</TableBody>
						</Table>
					</Loader>
				</Grid>
			</Grid>
		</Card>
	)
}

export default withRouter(DataTable);