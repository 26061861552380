import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import UniversalCookie from 'universal-cookie';

import DashboardHeader from 'src/components/header';
import DashboardSidebar from 'src/components/sidebar';

const cookie = new UniversalCookie();

const DashboardLayoutRoot = experimentalStyled('div')(
	({ theme }) => ({
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%'
	})
);

const DashboardLayoutWrapper = experimentalStyled('div')(
	({ theme }) => ({
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
		paddingTop: 64,
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 280
		}
	})
);

const DashboardLayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
	padding: '2em',
});

const DashboardLayout = () => {
	const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

	const navigate = useNavigate();
	
	if (!cookie.get('userEmail'))
		navigate('/auth/client/sign-in');

	if (!cookie.get('userType'))
		navigate('/auth/client/sign-in');
	
	if (window.location.pathname.includes('staff') && cookie.get('userType') === 'admin' || window.location.pathname.includes('client') && cookie.get('userType') === 'admin')
	 	navigate('/admin');

	if (window.location.pathname.includes('staff') && cookie.get('userType') === 'client')
		navigate('/client');

	if (window.location.pathname.includes('client') && cookie.get('userType') === 'staff')
		navigate('/staff');

	return (
		<DashboardLayoutRoot>
			<DashboardHeader onMobileNavOpen={() => setMobileNavOpen(true)} />
			<DashboardSidebar
				onMobileClose={() => setMobileNavOpen(false)}
				openMobile={isMobileNavOpen}
			/>
			<DashboardLayoutWrapper>
				<DashboardLayoutContainer>
					<DashboardLayoutContent>
						<Outlet />
					</DashboardLayoutContent>
				</DashboardLayoutContainer>
			</DashboardLayoutWrapper>
		</DashboardLayoutRoot>
	);
};

export default DashboardLayout;
