import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';

interface IStatsProps {
	id: string
	name: string
	created_at: string
	is_default: boolean
	workspace_name: string
	is_answered: boolean
	is_editable: boolean
}

function Stats(props: IStatsProps) {
	const { id, name, created_at, is_default, workspace_name, is_answered, is_editable } = props;
	const { t } = useTranslation();

	return (
		<Box component={RouterLink} to={'/client/forms/detail'}>
			<Card variant="outlined" sx={{ boxShadow: 6, pt: 6, pb: 2 }}>
				<CardContent sx={{ textAlign: 'center', py: 0}}>
					<Typography variant='h1' fontWeight='fontWeightLight' sx={{ pt: 0, pb: 1 }}>
						<Box>
							{name}
						</Box>
					</Typography>
					<DescriptionSharpIcon sx={{ fontSize: 99, color:'gray' }}></DescriptionSharpIcon>
					<Typography variant='h3' sx={{ pt: 1, pb: 3 }}>
						<Box fontWeight='fontWeightLight' color={'gray'}>
							{created_at}
						</Box>
					</Typography>
				</CardContent>
				
				<CardContent sx={{ textAlign: 'right', pt: 2}}>
					<Typography sx={{ mb: 0 }} color="text.secondary">
						{workspace_name}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
}

export default Stats;